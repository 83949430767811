.designer {
  /* margin-top: 5rem; */
  display: grid;
  width: 100%;
  /* grid-template-columns: 1fr 1.2fr; */
  /* grid-template-columns: 33% 50%; */
  grid-template-columns: 40% 50%;
  column-gap: 50px;
  row-gap: 30px;
  justify-content: center;
  justify-items: flex-end;
}

.designer > *:last-child {
  grid-column: 2;
}

/* .save {
  margin-left: calc(87.25% + 40px);
} */

@media screen and (max-width: 1261px) {
  .designer {
    column-gap: 40px;
  }
}

@media only screen and (max-width: 769px) {
  .designer {
    box-sizing: border-box;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
    column-gap: 0px;
    padding: 0 1rem;
    /* row-gap: 50px; */
    justify-items: center;
  }
  .designer > :first-child {
    grid-column: 1;
    grid-row: 1;
    /* overflow-x: scroll; */
  }

  .designer > *:last-child {
    grid-column: 1;
    /* width: 100%; */
  }
}
