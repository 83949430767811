.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
  font-family: "Montserrat";
  row-gap: 1.5rem;
  overflow: hidden;
}

.capsule-textfield {
  display: flex;
  justify-content: space-between;
  /* column-gap: 20%; */
  /* justify-content: flex-start; */
  /* align-content: flex-start; */
  align-items: center;
}

.capsule-textfield > div {
  width: 60%;
  margin: 0;
  text-align: left;
}

.capsule-textfield fieldset {
  border-radius: 30px !important;
}

.capsule-textfield > div > div {
  height: 40px;
  width: 70%;
}

.capsule-textfield input[type="range"] {
  width: 70%;
}

#tempColor,
#textColor {
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

#tempColor::-webkit-color-swatch,
#textColor::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}
#tempColor::-webkit-color-swatch-wrapper,
#textColor::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.layouts {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  column-gap: 20px;

  /* border: 1px solid black; */
  border-radius: 20px;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-bottom: 8px;
  --scroll-size: 6px;
  --scroll-radius: 20px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb: linear-gradient(45deg, #00aeff, #a68eff);
  scrollbar-color: var(--scroll-thumb-color, grey)
    var(--scroll-track, transparent);
  scrollbar-width: thin;
}

.layouts > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  min-width: 30%;
  cursor: pointer;
}

.layouts > div > div {
  border: 1px solid #00cfd6;
  border-radius: 20px;
}

.layout-text {
  font-size: 14px;
  margin-top: 5px;
}

.layout {
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.layouts::-webkit-scrollbar {
  width: var(--scroll-size, 10px);
  height: var(--scroll-size, 10px);
}
.layouts::-webkit-scrollbar-track {
  margin: 0 10px;
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}
.layouts::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color, grey);
  background-image: var(--scroll-thumb, none);
  border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
}

@media screen and (max-width: 450px) {
  .main {
    padding: 3rem 1.5rem;
  }
}
