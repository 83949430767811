.card {
  display: grid;
  /* grid-template-rows: 60px 90%; default setting */
  grid-template-rows: max-content min-content;
  width: 100%;
  height: max-content;
  font-family: "Montserrat";
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.header {
  display: flex;
  background-color: #e6edf5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-content: center;
  justify-content: space-around;
}

.main {
  background-color: white;
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  /* padding-bottom: 6rem; */
}

.main ol,
.main p {
  font-size: 14px;
  padding-left: 20px;
}

.main li {
  margin-top: 4px;
}

/* .main span {
  font-weight: bold;
} */

.icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.icons button {
  background-color: white;
  margin-left: 10px;
  padding: 8px 0;
}
.icons button img {
  width: 60%;
  height: 50%;
}

#htmlCode {
  width: 100%;
}

@media screen and (max-width: 426px) {
  .header {
    padding: 1rem;
  }

  .icons {
    justify-content: center;
    /* align-items: center; */
  }
}
