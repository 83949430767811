.card {
  display: grid;
  /* grid-template-rows: 0.2fr 1fr; */
  /* grid-template-rows: 10% min-content; TEST */
  /* max-width: 622px; */
  /* margin: 0 auto; */
  /* overflow: hidden; */
  grid-template-rows: max-content min-content min-content;
  width: 100%;
  max-width: 700px;
  justify-self: start;
  height: max-content;
  position: sticky;
  top: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
}

.header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0.5rem;
  padding-top: 0;
  padding-left: 2rem;
  height: fit-content;
  background-color: #e6edf5;
  font-family: "Montserrat";
}

.content {
  padding: 2rem 3rem;
  background-color: white;
  /* border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; */
  min-height: 200px;
  overflow: hidden;
}

.main {
  /* display: flex; */
  /* flex-direction: row; */
  grid-row: 2;
}

.main p {
  margin: 0 0;
  font-weight: 500;
}

/* .main .text {
  margin-left: 15px;
  margin-right: 15px;
  flex-wrap: wrap;
  max-width: 350px;
} */

/* .td-text {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 400px;
} */

/* .contact-name {
  color: #00cfd6;
  margin-right: 5px;
} */

.social {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  padding-left: 6px;
  border-left: 4px solid rgb(0, 207, 214);
}

/* .social a {
  color: #00cfd6;
  margin-left: 5px;
  font-size: 21px;
} */

/* .social a img {
  width: 21px;
} */

/* .social-layout2 {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.social-layout2 a {
  color: #00cfd6;
  margin-left: 5px;
  font-size: 21px;
}

.social-layout2 a img {
  width: 21px;
} */

/* #branding-seperator {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
} */

.footer {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  min-height: 20px;
  /* margin-top: 1rem; */
  /* padding-bottom: 1rem; */
}

.loading {
  width: 400px;
}

@media screen and (max-width: 1261px) {
  .content {
    /* max-width: 400px; */
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 769px) {
  .card {
    margin: 0 auto;
  }

  .content {
    padding: 2rem 0.5rem;
    overflow-x: scroll;
  }
}
