.buttons {
  display: flex;
  height: 150%;
  background-color: #e6edf5;
  flex-grow: 1;
  border-radius: 20px;
}

.buttons button {
  position: relative;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #2c4166;
  width: 25%;
  border: none;
}

.activeButton {
  background-color: #304aff !important;
  color: white !important;
  /* background-color: #2c4166 !important; */
}

.buttons button span {
  /* To center button text */
  position: absolute;
  top: 23%;
}
