.misc-page {
  box-sizing: border-box;
  width: 90%;
  max-width: 1450px;
  margin: -1rem auto;
  padding: 2rem 4rem;
  border-radius: 20px;
  background-color: white;
  font-family: "Montserrat";
}

@media screen and (max-width: 600px) {
  .misc-page {
    padding: 2rem;
  }
}
