/* .text {
  margin-left: 15px;
  margin-right: 15px;
  flex-wrap: wrap;
  max-width: 350px;
} */

.td-text {
  /* padding-left: 15px; */
  padding-right: 15px;
  max-width: 400px;
}

/* .contact-name {
  color: #00cfd6;
  margin-right: 5px;
  padding-right: 5px;
} */
