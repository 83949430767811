.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
  row-gap: 20px;
  justify-items: center;
}

.main > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.capsule-textfield fieldset {
  border-radius: 30px !important;
}

.capsule-textfield > label {
  color: blue;
  font-size: 40px;
  width: 50px;
  height: 50px;
}

.capsule-textfield input {
  height: 10px;
  font-size: 14px;
}

.capsule-textfield input::placeholder {
  opacity: 0.8;
}

.capsule-textfield > div {
  flex-basis: 85%;

  /* testing standard box */
  /* display: flex;
  column-gap: 5px;
  flex-basis: 75%; */
}

.capsule-textfield input:-webkit-autofill,
.capsule-textfield input:-webkit-autofill:hover,
.capsule-textfield input:-webkit-autofill:focus,
.capsule-textfield input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width: 450px) {
  .main {
    padding: 3rem 1.5rem;
  }
}
