.container {
  /* margin: 20px auto; */
  /* max-width: 640px; */
  max-width: 500px; /*TEST*/
  /* max-height: 400px; */
}

img {
  max-width: 100%;
}
/* 
.cropper-view-box,
.cropper-face {
  border-radius: 0%;
} */
