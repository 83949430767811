@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.landing-page {
  background-color: white;
  margin: auto;
  max-width: 1450px;
  /* padding-bottom: 3rem; */
}

.landing-page a {
  width: max-content;
}

.initial-display {
  display: grid;
  grid-template-columns: 50% 45%;
  /* margin-top: 4rem; */
}

.initial-display > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3rem 0 4rem;
}

.arrow {
  display: flex;
  justify-content: space-between;
}

.arrow img {
  width: 200px;
  transform: rotateZ(-30deg);
}

/* HERE */

.initial-image {
  display: flex;
  align-items: center;
}

.initial-image > img {
  width: 100%;
}

.support {
  box-sizing: border-box;
  margin: 6rem auto;
  padding: 2.5rem 6rem;
  border-radius: 20px;
  box-shadow: 0px 7px 40px 1px rgba(0, 0, 0, 0.15);
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.support > h1 {
  font-size: 38px;
}

.support img {
  width: 60px;
}

.support > div:nth-child(2) {
  display: flex;
  margin-top: 0.5rem;
  padding: 0 1rem;
  justify-content: space-around;
}

.advantages {
  display: grid;
  margin-top: 10rem;
  grid-template-columns: 45% 45%;
  column-gap: 40px;
  row-gap: 50px;
}

.advantages > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.advantages img {
  width: 100%;
}

.sec-adv-text,
.four-adv-text {
  margin-left: 10%;
}

.features {
  display: flex;
  margin: 4rem auto;
  width: 90%;
  flex-direction: column;
  align-items: center;
}

.features > h2 {
  text-align: center;
  margin-bottom: 3rem;
  max-width: 736px;
}

.group_25 > div {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px 7px 40px 1px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.hover-cards {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
  transition: bottom 0.2s ease-in-out;
  border-radius: 20px;
  padding: 2rem 2rem;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
  width: 33%;
}

.hover-cards span,
.hover-cards h3 {
  font-family: "Bebas Neue";
  letter-spacing: 0.9px;
  font-size: 30px;
  margin: 0;
  font-weight: normal;
  height: max-content;
  text-align: center;
  text-transform: uppercase;
}

.hover-cards p {
  text-align: justify;
}

.hover-cards:hover,
.hover-cards#active {
  background: linear-gradient(
    -91.778803105493deg,
    rgba(85, 176.4, 255, 1) 0%,
    rgba(55.25, 79.78, 255, 1) 100%
  );
  bottom: 50px;
  color: white;
}

.hover-cards:hover p,
.hover-cards#active p {
  color: white;
}

.benefits {
  display: grid;
  max-width: 1200px;
  margin: auto;
  padding: 4rem 2rem;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 50px;
  column-gap: 50px;
}

.benefits div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.benefits img {
  width: 100%;
}

.information {
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 85%;
  padding: 2rem;
  background-image: url("../../../public/images/info_back.png");
  /* background-image: linear-gradient(to top, #3850ff, #54adff); */

  color: white !important;
  border-radius: 40px;
}

.information > div {
  margin-top: 1rem;
}

.footer {
  box-sizing: border-box;
  padding: 2rem;
  margin: 6rem auto;
  margin-bottom: 0;
  width: 90%;
  border-radius: 20px;
  box-shadow: 0px 7px 40px 1px rgba(0, 0, 0, 0.15);
}

.footer-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  /* column-gap: 40px; */
}

.footer-grid > div:first-child {
  position: relative;
  bottom: 8px;
}

.footer img {
  width: 120px;
}

.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}

.footer .hr {
  margin-top: 1.5rem;
}

#mobile-footer-button {
  display: none;
}

.hr hr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #55b0ff, #3750ff, #55b0ff);
}

.copyright {
  text-align: center;
  font-family: Source Sans Pro;
  color: #374fff;
}

/* Universal */
.create-sig-button {
  color: white;
  /* background-color: #304aff; */
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: max-content;
  font-size: 20px;
  font-weight: bold;
  background: linear-gradient(180deg, #55b0ff 0%, #3750ff 93.98%);
}

.create-sig-button-inverse {
  color: #304aff;
  background-color: white;
  border: 1px solid #304aff;
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: max-content;
  font-size: 20px;
  font-weight: bold;
}

.heading {
  line-height: 56px;
  font-weight: normal;
  margin: 0;
  font-size: 48px;
  font-family: Bebas Neue;
  letter-spacing: 1.44px;
  color: #000844;
}

.small-heading {
  line-height: 46px;
  font-size: 38px;
  font-weight: normal;
  margin: 0;
  font-family: Bebas Neue;
  letter-spacing: 1.44px;
  color: #000844;
}

.text {
  font-family: Source Sans Pro;
  line-height: 26px;
  font-size: 17px;
  color: #7d7d7d;
}

a {
  text-decoration: none;
}

/* Responsiveness, WARNING DO NOT TOUCH */

@media screen and (max-width: 1220px) {
  .arrow img {
    display: none;
  }

  .group_25 > div {
    flex-wrap: wrap;
    column-gap: 0;
  }

  .hover-cards {
    min-width: 45%;
  }
}

@media screen and (max-width: 770px) {
  .support {
    padding: 2.5rem 0;
  }
}

@media screen and (max-width: 990px) {
  .information {
    background-image: linear-gradient(to top, #3850ff, #54adff);
  }
}

/* @media screen and (max-width: 426px) { */
@media screen and (max-width: 660px) {
  .initial-display {
    grid-template-columns: 100%;
  }

  .initial-display > div:nth-child(1) {
    padding: 2.5rem;
  }

  .initial-image > img {
    margin: auto;
    width: 90%;
  }

  .support {
    margin: 2rem auto;
  }

  .support img {
    width: 9vw;
  }

  .advantages {
    margin-top: 3rem;
    padding: 0 5%;
    grid-template-columns: 100%;
  }

  .advantages div {
    margin: 0;
  }

  .sec-adv-img {
    grid-row: 3;
  }

  .four-adv-img {
    grid-row: 7;
  }

  .hover-cards {
    min-width: 90%;
    margin: 0;
  }

  .benefits {
    grid-template-columns: 100%;
  }

  .benefits .benefits-image {
    grid-column: 1 !important;
  }

  .benefits .benefits-text {
    grid-column: 1 !important;
  }

  .footer-grid {
    grid-template-columns: 1fr 1fr;
  }

  .footer-grid > div:first-child {
    grid-column: 1 / span 2;
    margin-right: 0 !important;
    text-align: center;
  }

  .footer-links {
    text-align: center;
    margin-bottom: 1rem;
  }

  .footer a {
    height: min-content;
  }

  .footer-buttons .create-sig-button {
    display: none;
  }

  #mobile-footer-button {
    display: block;
    margin: auto;
  }

  .create-sig-button {
    padding: 0.7rem 1.2rem;
    font-size: medium;
  }

  .create-sig-button-inverse {
    padding: 0.7rem 1.2rem;
    font-size: medium;
  }

  .text {
    font-size: 18px;
  }
}
