.designer {
  margin-top: 5rem;
  display: grid;
  /* grid-template-columns: 1fr 1.2fr; */
  grid-template-columns: 33% 50%;
  column-gap: 40px;
  justify-content: center;
  justify-items: flex-end;
}

.designer > *:last-child {
  grid-column: 2;
}

@media only screen and (max-width: 600px) {
  .designer {
    grid-template-columns: 100%;
    grid-template-rows: 1fr 1fr;
    column-gap: 0px;
    row-gap: 50px;
    justify-items: center;
  }
  .designer > :first-child {
    grid-column: 1;
    grid-row: 1;
    /* overflow-x: scroll; */
  }

  .designer > *:last-child {
    grid-column: 1;
    /* width: 100%; */
  }
}
