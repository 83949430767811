.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
  font-family: "Montserrat";
  row-gap: 1.5rem;
}

.capsule-textfield {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.capsule-textfield > div {
  width: 65%;
  margin: 0;
  text-align: left;
}

.capsule-textfield fieldset {
  border-radius: 30px !important;
}

.capsule-textfield > div > div {
  height: 40px;
  width: 70%;
}

.capsule-textfield input[type="range"] {
  width: 70%;
}

#tempColor,
#textColor {
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

#tempColor::-webkit-color-swatch,
#textColor::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}
#tempColor::-webkit-color-swatch-wrapper,
#textColor::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.layouts {
  display: flex;
  column-gap: 20px;
}

.layouts div {
  border: 1px solid #00cfd6;
  border-radius: 20px;
  cursor: pointer;
}

.layout {
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

.upload {
  height: 8rem;
  width: 8rem;
  border-radius: 15px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #2c4166;
  border: 2px solid #e6edf5;
  text-transform: capitalize;
}
