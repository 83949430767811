@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&display=swap");
.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
}

.button-text {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

@media screen and (max-width: 450px) {
  .main {
    padding: 3rem 1.5rem;
  }
}
