@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.auth {
  width: 100vw;
  /* height: 100vh; */
  /* max-height: 100vh; */
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
}

.auth form {
  padding: 2rem 5rem;
  min-width: 25vw;
  /* max-width: 30vw; */
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-x: hidden;
  overflow-y: scroll; */
  /* transition: height 2s ease-in-out; */
}

.auth form > div {
  width: 100%;
  margin-top: 2rem;
}

.auth button {
  width: 240px;
  /* margin-top: 1rem; */
  margin-top: 2.5rem !important;
  text-transform: capitalize;
  font-size: large;
  font-family: "Montserrat";
  background-color: #304aff;
  /* background-color: #2c4166; */
}

.auth .main {
  margin-top: 3rem;
}

.auth .text {
  margin-left: 8px;
}

.phoneNum {
  flex-direction: row-reverse;
  margin-top: 2rem !important;
  column-gap: 20px;
}

.phoneNum input {
  border: none;
  padding: 4px 0 5px;
  font-size: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.phoneNum input:focus {
  outline: none;
}

/* .google-auth-button {
  height: 48px;
  background-color: #4285f4;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Roboto" !important;
  border-radius: 4px;
  text-transform: none !important;
}

#google-logo {
  height: 100%;
}

#google-logo img {
  height: 100%;
}

#google-text {
  margin: auto;
} */

@media screen and (max-width: 500px) {
  .auth form {
    padding: 1rem 2rem 2rem 2rem;
    overflow-y: scroll;
    max-height: 90vh;
  }

  .auth form > div {
    width: 100%;
    margin-top: 1rem;
  }

  .auth h1 {
    margin-bottom: 0;
  }
}

.error {
  box-sizing: border-box;
  max-width: fit-content;
}

@media screen and (max-width: 426px) {
  .auth {
    margin-bottom: 0;
  }
}
