.background {
  height: max-content;
  max-width: 1450px;
  padding: 2rem 0 5rem 0;
  margin: auto;
}

.navbar {
  /* margin: auto; */
  display: flex;
  /* position: relative;
    z-index: 3; */
  /* margin: 2rem auto !important; */
  /* position: sticky;
  top: 2rem; */
  /* margin-bottom: 0 !important; */
  font-family: Source Sans Pro;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width: 85%;
  margin: auto;
  padding: 1rem 1.5rem;
  box-shadow: 0px 7px 40px 1px rgba(0, 0, 0, 0.15);
}

.navbar a {
  text-decoration: none;
  cursor: pointer;
  margin-left: 2rem;
  color: #7d7d7d;
  font-size: larger;
}

.navrows {
  display: flex;
  align-items: center;
}

.logo img {
  width: 140px;
  /* height: 0px; */
}

.dropdown {
  /* display: flex; */
  display: none;
  width: 100%;
  justify-content: space-between;
}

.dropdown-logo {
  text-align: center;
  margin: 1rem 0;
}

.dropdown-logo img {
  width: 140px;
}

.dropdown-buttons {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
}

.dropdown-buttons a {
  width: 90%;
}

.dropdown-buttons div {
  box-sizing: border-box;
  margin-top: 1rem;
  padding: 0.7rem;
  text-align: center;
  width: 100%;
  font-size: 15px;
}

#dropdown-box a {
  color: black;
}

.create-sig-button {
  color: white;
  /* background-color: #304aff; */
  background: linear-gradient(180deg, #55b0ff 0%, #3750ff 93.98%);
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: max-content;
  font-size: 20px;
  font-weight: bold;
}

.create-sig-button-inverse {
  color: #304aff;
  background-color: white;
  border: 1px solid #304aff;
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: max-content;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 660px) {
  .background {
    padding: 2rem 0 3rem 0;
  }

  .navrows {
    display: none;
  }

  .navbar {
    width: 80%;
    padding: 0.2rem 1.5rem;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .logo img {
    width: 125px;
    /* height: 0px; */
  }

  .dropdown {
    display: flex;
  }

  .create-sig-button {
    padding: 0.7rem 1.2rem;
    font-size: medium;
  }

  .create-sig-button-inverse {
    padding: 0.7rem 1.2rem;
    font-size: medium;
  }
}
