.background {
  height: max-content;
  max-width: 1450px;
  padding: 5rem 0 2rem 0;
  margin: auto;
}

.footer {
  background-color: white;
  box-sizing: border-box;
  padding: 2rem;
  margin: auto;
  margin-bottom: 0;
  width: 90%;
  border-radius: 20px;
  box-shadow: 0px 7px 40px 1px rgba(0, 0, 0, 0.15);
}

.footer li {
  width: max-content;
}

.footer-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  /* column-gap: 40px; */
}

.footer-grid > div:first-child {
  position: relative;
  bottom: 8px;
}

.footer img {
  width: 200px;
  margin: 20px 0;
}

.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  row-gap: 20px;
}

.footer-links li {
  margin-bottom: 15px;
  cursor: pointer;
}

.footer .hr {
  margin-top: 1.5rem;
}

.mobile-footer-button {
  display: none;
}

.hr hr {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #55b0ff, #3750ff, #55b0ff);
}

.copyright {
  text-align: center;
  font-family: Source Sans Pro;
  color: #374fff;
}

/* Universal */

.create-sig-button {
  color: white;
  /* background-color: #304aff; */
  background: linear-gradient(180deg, #55b0ff 0%, #3750ff 93.98%);
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: max-content;
  font-size: 20px;
  font-weight: bold;
}

.create-sig-button-inverse {
  color: #304aff;
  background-color: white;
  border: 1px solid #304aff;
  padding: 1rem 1.5rem;
  border-radius: 40px;
  width: max-content;
  font-size: 20px;
  font-weight: bold;
}

.small-heading {
  line-height: 56px;
  font-size: 38px;
  font-weight: normal;
  margin: 0;
  font-family: Bebas Neue;
  letter-spacing: 1.44px;
  color: #000844;
}

.text {
  font-family: Source Sans Pro;
  line-height: 26px;
  font-size: 17px;
  color: #7d7d7d;
}

/* Responsiveness */

/* @media screen and (max-width: 426px) { */
@media screen and (max-width: 660px) {
  .footer li {
    width: auto;
  }

  .footer-grid {
    grid-template-columns: 1fr 1fr;
  }

  .footer-grid > div:first-child {
    grid-column: 1 / span 2;
    margin-right: 0 !important;
    text-align: center;
  }

  .footer-links {
    text-align: center;
    margin-bottom: 1rem;
  }

  .footer a {
    height: min-content;
  }

  .footer-buttons .create-sig-button,
  .footer-buttons .create-sig-button-inverse {
    display: none;
  }

  .footer-buttons {
    text-align: center;
    grid-column: 1 / span 2;
    align-items: center;
  }

  .mobile-footer-button {
    display: block;
    margin: auto;
  }

  .create-sig-button {
    padding: 0.7rem 1.2rem;
    font-size: medium;
  }

  .create-sig-button-inverse {
    padding: 0.7rem 1.2rem;
    font-size: medium;
  }

  .text {
    font-size: 18px;
  }
}
