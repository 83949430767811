.dashboard {
  /* padding: 10rem; */
  padding: 0 6% 0 6%;
  font-family: "Montserrat";
}

.dashboard > div {
  padding: 3rem;
  background-color: white;
  border-radius: 20px;
}

.dashboard button {
  font-family: "Montserrat";
  text-transform: capitalize;
  background-color: #304aff;
  /* background-color: #2c4166; */
}

.links {
  display: flex;
  justify-content: space-between;
}

.dashboard form {
  border-radius: 30px;
}

#last-link {
  margin-left: 20px;
}

.signatures {
  display: flex;
  /* overflow-x: scroll; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  column-gap: 20px;
  row-gap: 30px;
  margin: 5rem 0;
  padding: 10px;
}

.signatures > div {
  box-sizing: border-box;
  height: 12rem;
  /* width: 24rem; */
  width: 22rem;
  max-width: 95%;
  /* min-width: 33%; */
  border-radius: 20px;
  box-shadow: 0 0.1rem 0.3rem rgba(116, 133, 163, 0.7);
}

.signatures > div:not(:last-child) {
  display: grid;
  grid-template-rows: 75% 25%;
}

.signatures > *:last-child {
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  /* width: 100%; */
  /* min-width: 80vw; */
}

/* .footer-buttons {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.footer-buttons a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f4fa;
  padding: 5px;
  border-radius: 30px;
  width: 9%;
  height: 65%;
  color: #2c4166;
}

.footer-buttons button {
  color: #2c4166;
  background-color: #f0f4fa;
} */

@media screen and (max-width: 500px) {
  .dashboard {
    display: flex;
    justify-content: center;
  }

  .dashboard > div {
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 95vw;
  }

  .links {
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
  }

  .links > div {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    column-gap: 0;
    width: 100%;
  }

  .links > div a {
    margin-top: 10px;
    width: 90%;
  }

  .links a > button {
    width: 100%;
  }

  .links > div > button {
    width: 90%;
  }

  #last-link {
    margin-left: 0;
  }

  .signatures {
    flex-direction: column;
    row-gap: 30px;
    margin: 3rem 0;
    align-items: center;
  }
}
