.crop-screen {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 400px;
  height: 500px; */
  max-height: 90vh;
  max-width: 65%;
  border-radius: 20px;
  padding: 0;
  overflow: scroll;
  /* boxshadow: 24;
    bgcolor: "background.paper"; */
}

.crop-screen > div {
  border-radius: 20px;
}

.crop-container-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.crop-controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
}

.crop-controls > div,
.crop-controls > button {
  margin-top: 1rem !important;
}

/* .crop-controls > div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
} */

.shape-controls {
  /* position: absolute; */
  /* background-color: white; */
  /* width: 100%; */
  padding: 0.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.shape-controls > span {
  margin: 0 20px;
}

.crop-button {
  margin: 2rem 0 0 0;
  padding: 0;
}

/* react-easy-crop */

/* .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  } */

/* .crop-controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: center;
  width: 100%;
} */

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 80%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* .crop-controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.crop-controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
} */

/* Toggle Switch */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
