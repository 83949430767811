.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
  font-family: "Montserrat";
}

.main > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main input {
  align-self: flex-end;
}

.main label {
  margin-right: 2rem;
  display: inline;
}

.capsule-textfield {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.capsule-textfield .contact-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-field > div > label {
  text-transform: capitalize;
}

.capsule-textfield fieldset {
  border-radius: 30px !important;
}

.capsule-textfield input {
  height: 0.5rem;
}

.capsule-textfield > .contact-field > div {
  width: 46%;
  padding-top: 0.3rem; /* to center label */
}

.addfield {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: flex-end;
  margin-right: 5%;
}

.addfield > div > div {
  height: 40px;
}

.seperator {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: -3rem;
  margin-left: -3rem;
  width: auto;
}

.cross {
  height: 20px;
  cursor: pointer;
}

.capsule-textfield input:-webkit-autofill,
.capsule-textfield input:-webkit-autofill:hover,
.capsule-textfield input:-webkit-autofill:focus,
.capsule-textfield input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media only screen and (max-width: 769px) {
  .main {
    padding: 2rem 1.5rem;
  }
}
