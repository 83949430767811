.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
  font-family: "Montserrat";
  row-gap: 1.5rem;
}

.upload {
  height: 8rem;
  width: 8rem;
  border-radius: 15px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #2c4166;
  border: 2px solid #e6edf5;
  text-transform: capitalize;
}

.upload-csv {
  display: flex;
  column-gap: 1rem;
}

.details {
  font-size: small;
}

.details span {
  font-weight: bold;
}
