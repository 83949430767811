.card {
  display: grid;
  /* box-sizing: border-box; */
  /* grid-template-rows: 2; */
  grid-template-rows: 60px 90%;
  /* grid-template-columns: max-content; */
  width: 100%;
  margin: 0 auto;
  max-width: 460px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* max-height: max-content; */
  /* box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px,
    rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px,
    rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px,
    rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px,
    rgb(255, 85, 85) 40px -40px; */
}

.header {
  display: flex;
  /* border-radius: 20px; 
  background-color: #e6edf5;
  justify-content: space-around;
  justify-content: stretch; */
  grid-row: 1;
}

.header button:hover {
  border: none;
  background-color: #dae2ed;
  /* border-radius: 20px; */
}

.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
}

.main > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main input {
  align-self: flex-end;
}

.main label {
  margin-right: 2rem;
  display: inline;
}

.capsule-textfield fieldset {
  border-radius: 30px !important;
  /* height: 50px; */
}

.capsule-textfield input {
  height: 10px;
  /* padding: 0.5rem 0.5rem; */
}

.capsule-textfield > div {
  margin-bottom: 1rem;
}

/* .capsule-textfield > div label {

} */

.addfield {
  text-align: right;
  /* margin-top: 1rem; */
}

.addfield > div > div {
  height: 40px;
}

.seperator {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: -3rem;
  margin-left: -3rem;
  width: auto;
}

@media screen and (max-width: 1261px) {
  .card {
    max-width: 400px;
  }
}

@media only screen and (max-width: 769px) {
  .card {
    margin: auto;
  }
}
