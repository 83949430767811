.card {
  display: grid;
  grid-template-rows: 100%;
  width: 100%;
}

.header {
  display: flex;
  grid-row: 1;
}

.header button:hover {
  border: none;
  background-color: #dae2ed;
}

.main {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-radius: 20px;
  z-index: 1;
}

.main > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.main input {
  align-self: flex-end;
}

.main label {
  margin-right: 2rem;
  display: inline;
}

.capsule-textfield fieldset {
  border-radius: 30px !important;
}

.capsule-textfield input {
  height: 10px;
}

.capsule-textfield > div {
  margin-bottom: 1rem;
}

.addfield {
  text-align: right;
}

.addfield > div > div {
  height: 40px;
}

.seperator {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: -3rem;
  margin-left: -3rem;
  width: auto;
}
