.card {
  box-sizing: border-box;
  width: 95%;
  max-width: 900px;
  margin: auto;
  background-color: white;
  font-family: "Montserrat";
  border-radius: 20px;
  padding: 2rem 6rem 2rem 4rem;
}

.card > div:first-child {
  display: flex;
  justify-content: space-between;
}

/* .information {
  padding-top: 1rem;
} */

.information p {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  margin-top: 2rem;
}

.information p div {
  border-radius: 30px;
  margin-left: 0.5rem;
  /* padding-bottom: 1rem; */
}

/* .information p label {
  -webkit-transform: none;
  padding-bottom: 1rem;
} */

.information p input {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.information button {
  background-color: #304aff;
  font-size: 16px;
  color: white;
  margin: 0 0 0 0.5rem !important;
  border-radius: 30px;
  border: none;
  padding: 0.5rem 1rem;
}

.value {
  margin-left: 1.5rem;
}

.value svg {
  cursor: pointer;
}

/* .card button {
  margin-top: 3rem;
} */

.password {
  /* width: 200px; */
  max-width: 30%;
}

.password div {
  border-radius: 30px;
  margin-top: 0.5rem !important;
  /* padding-bottom: 1rem; */
}

.password button {
  background-color: #304aff;
  font-size: 16px;
  color: white;
  margin: 1rem 0 0 0;
  border-radius: 30px;
  border: none;
  padding: 0.5rem 1rem;
}

/* .password label {
  -webkit-transform: none;
  padding-bottom: 1rem;
} */

.password input {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.error {
  margin-top: 1rem;
}

.phoneinput input {
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 30px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

@media screen and (max-width: 768px) {
  .card {
    padding: 2rem;
  }

  .card > div:first-child {
    flex-direction: column;
  }

  .password {
    max-width: 100%;
  }

  .password button {
    margin: 1.2rem 0 0 0.5rem;
  }
}

@media screen and (max-width: 520px) {
  .phoneinput {
    /* display: block !important; */
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .phoneinput input {
    width: 130px;
  }
  .phoneinput button {
    display: block;
  }
}
