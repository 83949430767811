.preview {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  /* justify-content: center; */
  /* align-items: stretch; */
}

.preview img {
  /* overflow: hidden; */
  width: 120%;
}

.footer-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-buttons a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f4fa;
  cursor: pointer;
  padding: 5px;
  border-radius: 30px;
  width: 9%;
  height: 65%;
  color: #2c4166;
  /* color: #2c4166; */
}

.footer-buttons button {
  color: #2c4166;
  background-color: #f0f4fa !important;
}
